export function initScrollPosition() {

	let targets = document.querySelectorAll('[data-scroll-position]');

	if(!targets.length) return;

	let threshold = buildThresholdList();

	let options = {
			rootMargin: '0px 0px -150px',
			threshold,
	}

	let setInview = (entries, observer) => {
		entries.forEach(entry => {
			setScrollPosition(entry);
		});
	}

	let observer = new IntersectionObserver(setInview, options);

	targets.forEach(target => {
		target.dataset.scrollPosition = 0;
		observer.observe(target);
	});
}

function setScrollPosition(entry) {
	var viewportOffset = entry.target.getBoundingClientRect();
	if(viewportOffset.top > 0) {
		var percent = Math.floor(entry.intersectionRatio * 100);
		var decimal = entry.intersectionRatio.toFixed(2);
		var position = 0;
		position = percent;
		entry.target.setAttribute('style','--scroll-position: '+position+'%; --scroll-position-decimal: '+decimal+';');
		entry.target.dataset.scrollPosition = position;
	}
}

function buildThresholdList(numSteps = 30) {
  let thresholds = [];

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}
